import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import "../styles/prismjs/monokai.css";

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </div>
      </header>
      {children}
      <footer className="site-footer">
        <p>
          &copy; 2020 Antony Tran &bull; Theme by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://w3layouts.com"
          >
            W3Layouts
          </a>
        </p>
      </footer>
    </div>
  );
};
